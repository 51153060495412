<template>
	<!-- 相关分析 -->
	<div class="flex chartDiv" ref="chartDiv">
		<div class="axesChats" ref="axesChats" :class="isFullScreen?'fsCharts':''" @click="clearRightMenu()"></div>

		<div
			style="font-weight: 400;color: rgba(255, 255, 255, 0.85);font-size: 16px;position: absolute;left: 18px;top: 16px;">
			<span style="margin-right: 20px;">采样时间：{{data.time}}</span>
		</div>
		<div
			style="font-weight: 400;color: rgba(255, 255, 255, 0.65);font-size: 13px;position: absolute;left: 18px;top:42px">
			<span style="margin-right: 20px;" v-if="data.measComm">测点描述：{{data.measComm }} </span>
			<span>数据来源：{{data.dataSrc==0?'圣名科技':(data.dataSrc==1?'西安因联':(data.dataSrc==2?'容知日新':''))}} </span>
		</div>
		<div class="bg99" style="color: #ffffff;">
			<div class="controlFrame-item" v-if="data.correlation">
				<span>相关系数：{{data.correlation}}</span>
			</div>
			<!-- <div class="controlFrame-item">
				<span>x向起始值</span>
				<el-input v-model="axis.start" placeholder="请输入起始值下标" size="mini"></el-input>
			</div>
			<div class="controlFrame-item">
				<span>x向结束值</span>
				<el-input v-model="axis.end" placeholder="请输入结束值下标" size="mini"></el-input>
			</div> -->
			<div class="controlFrame-item">
				<span>Y向数据</span>
				<el-select v-model="yValue" placeholder="请选择" :popper-append-to-body="false" size="mini">
					<el-option v-for="item in yOptions" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<!-- <div class="controlFrame-item">
				<span>查看指定范围值</span>
				<el-input class="input" placeholder="请输入开始值" type="number" :min="0" v-model="axis.start">
				</el-input>
				<el-input class="input" placeholder="请输入结束值" type="number" v-model="axis.end">
				</el-input>
			</div> -->
			<div class="mt-10" style="text-align: right;">
				<el-button type="primary" @click="chatsChagne()">查看</el-button>
			</div>
			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.btnIndex === 1 }" @click="changeBtn(1)">
					单游
				</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 2 }" @click="changeBtn(2)">
					双游
				</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 3 }" @click="changeBtn(3)">
					倍频
				</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 4 }" @click="changeBtn(4)">
					边频
				</div>
				<div class="btnSome" :class="{ active: this.markIndex === 2 }" @click="changeMark(5)">
					清除
				</div>
			</div>
			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.chatsIndex == '加速度' }" @click="chatsChagne('加速度')">
					加速度
				</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '速度' }" @click="chatsChagne('速度')">
					速度
				</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '位移' }" @click="chatsChagne('位移')">
					位移
				</div>
			</div>
			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">
					全屏
				</div>
				<div class="btnSome" v-else @click="exitFullScreen()">
					退出
				</div>
			</div>
			<div ref="rightMenu" class="rightClickMenu" style="display: none;">
				<div>
					<el-button type="text" @click="openSetPoint()">标记</el-button>
					<el-button type="text" @click="openClearOnePoint()">取消标记</el-button>
					<el-button type="text" @click="openClearAllPoint()">取消全部标记</el-button>
				</div>
			</div>
			<el-dialog title="标记" :visible.sync="SetPointVisible" width="20%" :modal="false" :show-close="false">
				<el-input style="width: 90%; margin: 0 5%;" focus v-model="pointContext" placeholder="请输入内容"></el-input>
				<span slot="footer">
					<el-button @click="SetPointVisible = false">取 消</el-button>
					<el-button type="primary" @click="setPoint()">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="取消标记" :visible.sync="ClearOnePointVisible" width="20%" :modal="false" :show-close="false">
				<span style="width: 90%; margin: 0 5%;">{{'确认删除 ' + onePoint.value + ' 此标记吗？'}}</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ClearOnePointVisible = false">取 消</el-button>
					<el-button type="primary" @click="clearOnePoint()">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="取消全部标记" :visible.sync="ClearAllPointVisible" width="20%" :modal="false"
				:show-close="false">
				<span style="width: 90%; margin: 0 5%;">取消全部标记后，所有标记将消失！</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ClearAllPointVisible = false">取 消</el-button>
					<el-button type="primary" @click="clearAllPoint()">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	let axesChats = null;
	let option = {};
	export default {
		name: "axesChats",
		props: {
			chatsData: {
				type: Object,
				default () {
					return {};
				}
			},
			activeMeasDef: {
				type: String,
				default () {
					return "";
				}
			},
			row: {
				type: Object,
				default () {
					return {};
				}
			},
		},
		data() {
			return {
				// begin: "",
				// end: "",
				yValue: "",
				yOptions: [],
				btnIndex: "",
				markIndex: "",
				value: '',
				dataZoomStartArr: [0, 0],
				dataZoomEndArr: [98, 98],
				chatsIndex: this.activeMeasDef,
				currentPoint: {},
				seriesPoints: [],
				isFullScreen: false,
				data: {},
				pointContext: '',
				SetPointVisible: false,
				ClearOnePointVisible: false,
				ClearAllPointVisible: false,
				onePoint: '',
				axis: { //轴心轨迹数据
					start: '',
					end: ''
				},
			};
		},
		watch: {
			activeMeasDef(val) {
				this.chatsIndex = val;
			},
			chatsData(val) {
				this.init();
			},
		},
		mounted() {
			let that = this;
			watchFull(this.$refs.chartDiv, ifs => {
				if (axesChats) {
					axesChats.resize();
				}
				if (!ifs) {
					that.isFullScreen = false;
				}
			});
		},
		beforeDestroy() {
			if (axesChats) {
				axesChats.dispose();
				axesChats = null;
			}
			option = {};
		},
		methods: {
			//全屏
			fullScreen() {
				this.isFullScreen = true;
				beFull(this.$refs.chartDiv);
			},
			//退出全屏
			exitFullScreen() {
				this.isFullScreen = false;
				exitFull();
			},
			setDataZoom() {
				option.dataZoom[0].start = this.dataZoomStartArr[0];
				option.dataZoom[0].end = this.dataZoomEndArr[0];
				option.dataZoom[2].start = this.dataZoomStartArr[1];
				option.dataZoom[2].end = this.dataZoomEndArr[1];
				option.dataZoom[4].start = this.dataZoomStartArr[2];
				option.dataZoom[4].end = this.dataZoomEndArr[2];
			},
			chatsChagne(chatsIndex) {
				if (chatsIndex) {
					this.chatsIndex = chatsIndex;
				}
				this.$emit('chatsChagne', this.chatsIndex, {
					start: this.axis.start,
					end: this.axis.end,
					meas_id_b: this.yValue != this.row.meas_id ? this.yValue : ""
				})
			},
			//关闭右键按钮
			clearRightMenu() {
				this.$refs.rightMenu.style['display'] = 'none';
				this.$refs.rightMenu.style['left'] = "9999px";
				this.$refs.rightMenu.style['top'] = "9999px";
			},
			//打开标记弹窗
			openSetPoint() {
				this.clearRightMenu();
				this.SetPointVisible = true;
			},
			//打开取消标记弹窗
			openClearOnePoint() {
				for (var i in this.markList) {
					var item = this.markList[i];
					var flag = false;
					for (var i2 in item.markPoint) {
						var item2 = item.markPoint[i2];
						if (item2.xAxis - this.currentPoint.name < 200 && item2.xAxis - this.currentPoint
							.name > -200 && item.seriesIndex == this.currentPoint.seriesIndex) {
							this.clearRightMenu();
							// this.$confirm('确认删除' + item2.value + '此标记吗？', '取消标记', {
							// 	confirmButtonText: '确定',
							// 	cancelButtonText: '取消',
							// 	type: 'warning'
							// }).then(() => {
							// 	this.clearOnePoint();
							// }).catch(() => {});
							this.ClearOnePointVisible = true;
							this.onePoint = item2;
							return;
						}
					}
				}
			},
			//打开取消全部标记弹窗
			openClearAllPoint() {
				this.clearRightMenu();
				// this.$confirm('取消全部标记后，所有标记将消失！', '取消全部标记', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	type: 'warning'
				// }).then(() => {
				// 	this.clearAllPoint();
				// }).catch(() => {});
				this.ClearAllPointVisible = true;
			},
			//点击空白处设置数据
			clickZr(params, func) {
				const pointInPixel = [params.offsetX, params.offsetY];
				this.seriesPoints = [];
				//对series遍历
				for (var i = 0; i < option.series.length; i++) {
					let index = axesChats.convertFromPixel({
						seriesIndex: i
					}, [params.offsetX, params.offsetY]);
					let xIndex = index[0];
					let yIndex = index[1];
					let op = option;
					// 获取当前点击位置对应折线上的数据
					var xData = op.series[i].data[xIndex][0]
					var yData = op.series[i].data[xIndex][1]
					//记录所有坐标系的点
					var pointObj = {
						name: xData,
						value: yData,
						xIndex: xIndex,
						yIndex: yIndex,
						seriesIndex: i
					}
					this.seriesPoints.push(pointObj);
					//单独记录所点坐标系的点
					if (axesChats.containPixel({
							seriesIndex: i
						}, pointInPixel)) {
						this.currentPoint.name = xData;
						this.currentPoint.value = yData;
						this.currentPoint.xIndex = xIndex;
						this.currentPoint.yIndex = yIndex;
						this.currentPoint.seriesIndex = i;
					}
				}
				func();
			},
			//设置标记
			setPoint() {
				this.setDataZoom();
				const markPoint = {
					value: this.pointContext,
					xAxis: String(this.currentPoint.name),
					yAxis: String(this.currentPoint.value),
				};
				var flag = true;
				//添加之前先检查是否存在
				for (var item of this.markList) {
					if (item.seriesIndex == this.currentPoint.seriesIndex) {
						item.markPoint.push(markPoint);
						flag = false;
						break;
					}
				}
				//flag为true，表示数组中不存在
				if (flag) {
					var markObj = {
						markPoint: [markPoint],
						seriesIndex: this.currentPoint.seriesIndex,
					}
					this.markList.push(markObj);
				}
				for (var item of this.markList) {
					option.series[item.seriesIndex].markPoint = {
						itemStyle: {
							color: "#66ccff",
						},
						label: {
							color: "#ffffff",
						},
						data: item.markPoint,
					};
				}
				axesChats.setOption(option, true);
				this.SetPointVisible = false;
			},
			//清除单个标记
			clearOnePoint() {
				this.setDataZoom();
				//找出是否存在这个点，存在就删除
				for (var i in this.markList) {
					var item = this.markList[i];
					var flag = false;
					for (var i2 in item.markPoint) {
						var item2 = item.markPoint[i2];
						if (item2.xAxis - this.currentPoint.name < 200 && item2.xAxis - this.currentPoint
							.name > -200 && item.seriesIndex == this.currentPoint.seriesIndex) {
							item.markPoint.splice(i2, 1);
						}
						if (item.markPoint.length == 0) {
							flag = true;
						}
					}
					if (flag) {
						this.markList.splice(i, 1);
					}
				}
				//重新画标记
				for (var item of this.markList) {
					option.series[item.seriesIndex].markPoint = {
						itemStyle: {
							color: "#66ccff",
						},
						label: {
							color: "#ffffff",
						},
						data: item.markPoint,
					};
				}
				axesChats.setOption(option, true);
				this.ClearOnePointVisible = false;
			},
			//清除所有标记
			clearAllPoint() {
				this.setDataZoom();
				this.markList = [];
				for (var item of option.series) {
					item.markPoint = {
						itemStyle: {
							color: "#66ccff",
						},
						label: {
							color: "#ffffff",
						},
						data: [],
					};;
				}
				axesChats.setOption(option, true);
				this.ClearAllPointVisible = false;
			},
			getMeas(id) {
				this.$get('/backend-api/v2/unit/meas/list', {
					type: 0,
					unit_id: id
				}).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
						return
					}
					this.yOptions = data.data
					this.yValue = this.row.meas_id;
				})
			},
			init() {
				this.data = this.chatsData.data;
				this.yOptions = [];
				this.getMeas(this.chatsData.data.unit_id)
				// for (var item of this.rows) {
				// 	if (this.yValue == "") {
				// 		if (this.row.meas_id == item.meas_id) {
				// 			this.yValue = item.meas_id;
				// 		}
				// 	}
				// 	var obj = {
				// 		value: item.meas_id,
				// 		label: item.label
				// 	}
				// 	this.yOptions.push(obj);
				// }
				axesChats = this.$echarts.init(this.$refs.axesChats);
				option = {
					title: [{
							left: "22%",
							top: "5%",
							text: this.chatsData.data.name[0],
						},
						{
							top: "5%",
							left: "76%",
							text: this.chatsData.data.name[1],
						},
						{
							top: "49%",
							left: "76%",
							text: this.chatsData.data.name[2],
						},
					],
					toolbox: {
						show: true,
						top: 20,
						right: 20,
						zlevel: 899,
						feature: {
							dataZoom: {
								yAxisIndex: 'none',
								brushStyle: {
									color: "rgba(35, 136, 255, 0.1)",
									shadowBlur: 2
								}
							},
							restore: {}
						}
					},
					tooltip: {
						confine: true, // 加入这一句话
						trigger: 'axis',
						backgroundColor: 'rgba(34, 34, 34, 0.5)',
						borderWidth: 0,
						textStyle: {
							color: 'rgba(255, 255, 255, 1.0)'
						},
						position: function(pos, params, el, elRect, size) {
							var obj = {
								top: 10
							};
							obj['left'] = pos[0] + 10;
							obj['top'] = pos[1] - 60;
							return obj;
						},
						formatter: (param) => {
							if (param[0].axisIndex !== 0) {
								return [
									this.chatsData.data.tooltip[param[0].axisIndex].x + "：" + param[0].value[
										0] +
									this.chatsData
									.data.unit[param[0].axisIndex].x +
									"<br/>",
									this.chatsData.data.tooltip[param[0].axisIndex].y + "：" + param[0].value[
										1] +
									this.chatsData
									.data.unit[param[0].axisIndex].y
								].join('');
							}
						}
					},
					xAxis: [{
							name: this.chatsData.data.unit[0].x,
							nameTextStyle: {
								color: "#D4D5D7"
							},
							type: "value",
							max: (value) => {
								return value.max;
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: "#D4D5D7"
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#384049"
								},
								onZero: false
							},
						},
						{
							name: this.chatsData.data.unit[1].x,
							nameTextStyle: {
								color: "#D4D5D7"
							},
							type: "value",
							max: (value) => {
								return value.max;
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: "#D4D5D7"
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#384049"
								},
								onZero: false
							},
							gridIndex: 1,
						},
						{
							name: this.chatsData.data.unit[2].x,
							nameTextStyle: {
								color: "#D4D5D7"
							},
							type: "value",
							max: (value) => {
								return value.max;
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: "#D4D5D7"
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#384049"
								},
								onZero: false
							},
							gridIndex: 2,
						},
					],
					yAxis: [{
							name: this.chatsData.data.unit[0].y,
							nameTextStyle: {
								color: "#D4D5D7"
							},
							type: "value",
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: "#D4D5D7"
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#384049"
								},
								onZero: false
							},

						},
						{
							name: this.chatsData.data.unit[1].y,
							nameTextStyle: {
								color: "#D4D5D7"
							},
							type: "value",
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: "#D4D5D7"
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#384049"
								},
								onZero: false
							},
							gridIndex: 1,
						},
						{
							name: this.chatsData.data.unit[2].y,
							nameTextStyle: {
								color: "#D4D5D7"
							},
							type: "value",
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: "#D4D5D7"
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#384049"
								},
								onZero: false
							},
							gridIndex: 2,
						},
					],
					grid: [{
							left: "50px",
							top: "8%",
							right: "55%",
						},
						{
							top: "8%",
							left: "55%",
							right: '40px',
							bottom: "62%",
						},
						{
							left: "55%",
							right: '40px',
							top: "52%",
						},
					],
					series: [{
							data: this.chatsData.data.series[0],
							type: "line",
							symbolSize: 1,
							showSymbol: false,
							showAllSymbol: false,
							xAxisIndex: 0,
							yAxisIndex: 0,
							itemStyle: {
								normal: {
									color: "#4CD964",
									lineStyle: {
										color: "#4CD964"
									}
								},
							}
						},
						{
							data: this.chatsData.data.series[1],
							type: "line",
							symbolSize: 1,
							showSymbol: false,
							showAllSymbol: false,
							smooth: true,
							xAxisIndex: 1,
							yAxisIndex: 1,
							itemStyle: {
								normal: {
									color: "#A6E216",
									lineStyle: {
										color: "#A6E216"
									}
								},
							}
						},
						{
							data: this.chatsData.data.series[2],
							type: "line",
							symbolSize: 1,
							showSymbol: false,
							showAllSymbol: false,
							smooth: true,
							xAxisIndex: 2,
							yAxisIndex: 2,
							itemStyle: {
								normal: {
									color: "#F79305",
									lineStyle: {
										color: "#F79305"
									}
								},
							}
						},
					],
					dataZoom: [{
							id: 'dataZoom0',
							xAxisIndex: 0,
							show: true,
							height: 20,
							bottom: 10,
							start: this.dataZoomStartArr[0],
							end: this.dataZoomEndArr[0],
							filterMode: 'empty',
						},
						{
							id: 'dataZoom1',
							xAxisIndex: 0,
							type: "inside",
							show: true,
							height: 15,
						},
						{
							id: 'dataZoom2',
							xAxisIndex: 1,
							show: true,
							height: 20,
							bottom: '55%',
							start: this.dataZoomStartArr[1],
							end: this.dataZoomEndArr[1],
							filterMode: 'empty',
						}, {
							id: 'dataZoom3',
							xAxisIndex: 1,
							type: "inside",
							show: true,
							height: 15,
						},
						{
							id: 'dataZoom4',
							xAxisIndex: 2,
							show: true,
							height: 20,
							bottom: 10,
							start: this.dataZoomStartArr[2],
							end: this.dataZoomEndArr[2],
							filterMode: 'empty',
						}, {
							id: 'dataZoom5',
							xAxisIndex: 2,
							type: "inside",
							show: true,
							height: 15,
						}

					]
				};
				axesChats.setOption(option, true);
				this.clearAllPoint();
				//缩放时记录缩放的范围
				axesChats.on('dataZoom', (event) => {
					if (event.batch) {
						if (event.batch[0].dataZoomId == "dataZoom1") {
							this.dataZoomStartArr[0] = event.batch[0].start;
							this.dataZoomEndArr[0] = event.batch[0].end;
						} else if (event.batch[0].dataZoomId == "dataZoom3") {
							this.dataZoomStartArr[1] = event.batch[0].start;
							this.dataZoomEndArr[1] = event.batch[0].end;
						} else if (event.batch[0].dataZoomId == "dataZoom5") {
							this.dataZoomStartArr[2] = event.batch[0].start;
							this.dataZoomEndArr[2] = event.batch[0].end;
						}
					} else {
						if (event.dataZoomId == "dataZoom0") {
							this.dataZoomStartArr[0] = event.start;
							this.dataZoomEndArr[0] = event.end;
						} else if (event.dataZoomId == "dataZoom2") {
							this.dataZoomStartArr[1] = event.start;
							this.dataZoomEndArr[1] = event.end;
						} else if (event.dataZoomId == "dataZoom4") {
							this.dataZoomStartArr[2] = event.start;
							this.dataZoomEndArr[2] = event.end;
						}
					};
				});
				//右键弹出菜单，支持点击空白处
				axesChats.getZr().on("contextmenu", (params) => {
					this.clickZr(params, () => {
						if (this.currentPoint.seriesIndex == 0) {
							return;
						}
						//打开右键菜单
						this.pointContext = this.currentPoint.name;
						this.$refs.rightMenu.style['display'] = 'block';
						this.$refs.rightMenu.style['left'] = params.offsetX + 30 + "px";
						this.$refs.rightMenu.style['top'] = params.offsetY + 30 + "px";
					})
				});
			},
			changeBtn(btnIndex) {
				this.btnIndex = btnIndex;
				this.xAxisData = [];
				this.xAxisArr = [];
				axesChats.getZr().off("click");
				axesChats.getZr().on("click", (params) => {
					this.setDataZoom();
					this.clickZr(params, () => {
						if (this.currentPoint.seriesIndex == 0) {
							return;
						}
						switch (this.btnIndex) {
							case 1:
								var xAxisData1 = {};
								xAxisData1 = {
									xValue: this.currentPoint.name,
									yValue: this.currentPoint.value,
									xAxis: String(this.currentPoint.name)
								};
								var series = option.series[this.currentPoint.seriesIndex];
								series.markLine = {
									lineStyle: {
										normal: {
											color: "rgba(255, 38, 38, 1)",
										},
									},
									label: {
										color: "#ffffff",
										formatter: (params) => {
											return "(x=" + params.data.xValue + ",y=" +
												params.data.yValue + ")";
										}
									},
									data: [xAxisData1],
								}
								axesChats.setOption(option, true);
								break;
							case 2:
								//当前每一个series的游标
								var xAxisObj = {
									xValue: this.currentPoint.name,
									yValue: this.currentPoint.value,
									xAxis: String(this.currentPoint.name),
									flag: false,
									lineStyle: {
										type: 'solid'
									}
								};
								if (this.xAxisData.length < 1) {
									this.xAxisData.push(xAxisObj);
								} else if (this.xAxisData.length == 1) {
									this.xAxisData[0].flag = true;
									this.xAxisData.push(xAxisObj);
									xAxisObj.flag = true;
									xAxisObj.lineStyle.type = 'dashed';
									this.xAxisData[1].t = Math.abs(this.xAxisData[0].xValue - this
										.xAxisData[1]
										.xValue).toFixed(2);
									this.xAxisData[1].f = (1000/ this.xAxisData[1].t).toFixed(2);
									this.xAxisData[1].amp = Math.abs(this.xAxisData[0].yValue - this
										.xAxisData[1]
										.yValue).toFixed(2);
								} else if (this.xAxisData.length == 2) {
									this.xAxisData = [];
									this.xAxisData.push(xAxisObj);
								}
								var series = option.series[this.currentPoint.seriesIndex];
								series.markLine = {
									lineStyle: {
										normal: {
											color: "rgba(255, 38, 38, 1)",
										},
									},
									label: {
										color: "#ffffff",
										formatter: (params) => {
											if (params.data.flag) {
												if (params.dataIndex == 1) {
													return "(△t=" + params.data.t + ",△f=" +
														params.data.f + ",△Amp=" +
														params.data.amp + ")";
												} else {
													return "";

												}
											} else {
												return "(x=" + params.data.xValue + ",y=" +
													params.data.yValue + ")";
											}
										}
									},
									data: this.xAxisData
								}
								axesChats.setOption(option, true);
								break;
							case 3:
								this.xAxisData = [];
								for (let i = 1; i < 6; i++) {
									let indexVal = this.currentPoint.xIndex * i;
									if (indexVal < option.series[this.currentPoint.seriesIndex].data
										.length) {
										const xAxisObj = {
											xValue: this.currentPoint.name,
											yValue: this.currentPoint.value,
											xAxis: String(option.series[this.currentPoint
													.seriesIndex]
												.data[indexVal][0])
										};
										this.xAxisData.push(xAxisObj);
									}
								}
								var series = option.series[this.currentPoint.seriesIndex];
								series.markLine = {
									lineStyle: {
										normal: {
											color: "rgba(255, 38, 38, 1)",
										},
									},
									label: {
										color: "#ffffff",
										formatter: (params) => {
											if (params.dataIndex == 0) {
												return "(x=" + params.data.xValue + ",y=" +
													params.data.yValue + ")";
											} else {
												return "";
											}
										}
									},
									data: this.xAxisData,
								}
								axesChats.setOption(option, true);
								break;
							case 4:
								let xAxis = String(this.currentPoint.name);
								let indexCalc = "";
								let xAxisCalc = 1;
								var xAxisObj = {
									xValue: this.currentPoint.name,
									yValue: this.currentPoint.value,
									xAxis: String(this.currentPoint.name),
									flag: false,
									lineStyle: {
										type: 'solid'
									}
								}
								if (this.xAxisData.length < 1) {
									this.xAxisData.push(xAxisObj);
									this.xAxisArr.push(this.currentPoint.xIndex);
								} else if (this.xAxisArr.length == 1) {
									this.xAxisArr.push(this.currentPoint.xIndex);
									this.xAxisData[0].flag = true;
									xAxisObj.flag = true;
									xAxisObj.lineStyle.type = 'dashed';
									this.xAxisData.push(xAxisObj);
									indexCalc = this.xAxisArr[1] - this.xAxisArr[0];
									xAxisCalc = Math.abs(option.series[this.currentPoint.seriesIndex]
										.data[this.xAxisArr[1]][0] -
										option.series[this.currentPoint.seriesIndex].data[this
											.xAxisArr[0]][0]);
									this.xAxisData[0].f = Math.abs(this.xAxisData[0].xValue - this
										.xAxisData[1]
										.xValue).toFixed(2);
									for (let j = 1; j < 4; j++) {
										let subtractValue =
											Number(this.xAxisArr[0]) - Number(indexCalc * j);
										let subtractValue1 =
											Number(this.xAxisArr[0]) + Number(indexCalc * j);
										if (0 < subtractValue && subtractValue < option.series[this
												.currentPoint.seriesIndex].data.length && 0 <
											subtractValue1 && subtractValue1 < option.series[this
												.currentPoint.seriesIndex].data.length) {
											const xAxisObj = {
												xAxis: String(option.series[this.currentPoint
													.seriesIndex].data[subtractValue][0]),
												flag: true,
												lineStyle: {
													type: 'dashed'
												}
											};
											const xAxisObj1 = {
												xAxis: String(option.series[this.currentPoint
													.seriesIndex].data[subtractValue1][0]),
												flag: true,
												lineStyle: {
													type: 'dashed'
												}
											};
											this.xAxisData.push(xAxisObj, xAxisObj1);
										}
									}
								} else {
									this.xAxisArr = [this.currentPoint.xIndex];
									this.xAxisData = [xAxisObj];
								}
								var series = option.series[this.currentPoint.seriesIndex];
								series.markLine = {
									lineStyle: {
										normal: {
											color: "rgba(255, 38, 38, 1)",
										},
									},
									label: {
										color: "#ffffff",
										formatter: (params) => {
											if (params.data.flag) {
												if (params.dataIndex == 0) {
													return "(x=" + params.data.xValue + ",y=" +
														params.data.yValue + ",△f=" +
														params.data.f + ")";
												} else {
													return "";
												}
											} else {
												return "(x=" + params.data.xValue + ",y=" +
													params.data.yValue + ")";
											}
										}
									},
									data: this.xAxisData,
								}
								axesChats.setOption(option, true);
								break;
						}
					})
				});
			},

			changeMark(val) {
				const markList = [];
				this.btnIndex = val;
				axesChats.getZr().off("click");
				if (val == 5) {
					this.btnIndex = val;
					this.init();
				}
			},
		},
	};
</script>
<style>
	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance: textfield;
		/* Firefox */
	}
</style>

<style lang='scss' scoped>
	.chartDiv {
		.axesChats {
			height: 85vh;
			width: 100%;
		}

		.axesChats.fsCharts {
			height: 100vh;
			width: 100%;
			background-color: #000B17;
		}

		.active {
			background-color: #2388ff;
		}

		.controlFrame-item {
			width: 150px;
			margin-top: 5px;

		}

		::v-deep.el-input__inner {
			height: 30px;
			margin-top: 5px;
		}

		.demo-input-suffix {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}


		.bg99 {
			background-color: #000B17;
		}


		.bg99 {
			background-color: #000B17;
		}

		.btnSome {
			height: 60px;
			width: 60px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			cursor: pointer;
		}

		.btnSome:hover {
			opacity: 1;
			color: #aaa;
		}

		// .rightChange {
		//    background-color: #596067;
		// }
		.rightBtn {
			background-color: #596067;
			border-radius: 8px;
			overflow: hidden;
			width: 60px;
			margin-left: 90px;
		}

		.rightClickMenu {
			/*这个样式不写，右键弹框会一直显示在画布的左下角*/
			position: absolute;
			background-color: rgb(255, 255, 255, 0.8);
			color: rgb(0, 0, 0, 1);
			border-radius: 5px;
			left: -99999px;
			top: -999999px;
		}


		.rightClickMenu>div>.el-button {
			cursor: pointer;
			margin: 0;
			padding: 5px 10px;
			font-size: 14px;
			display: block;
			width: 100%;
			color: #000000;
		}

		.rightClickMenu>div>.el-button:hover {
			background-color: rgb(64, 158, 255, 0.8);
		}

		::v-deep .el-dialog {
			background-color: #ffffff !important;
			border-color: #FFFFFF !important;
		}

		::v-deep .el-dialog__header {
			text-align: center;
			color: #000000;
			padding: 10px;
		}

		::v-deep .el-dialog__title {
			color: #000000 !important;
		}

		::v-deep .el-input__inner {
			border-color: #409eff !important;
		}

		::v-deep .el-dialog__footer {
			padding: 10px 5%;
			border-color: none !important;
		}

		::v-deep .el-button {
			border-color: transparent !important;
		}
	}
</style>