<template>
	<div class="flex chartDiv" ref="chartDiv">
		<div class="timeDomainsChats" :class="isFullScreen ? 'fsCharts' : ''" ref="timeDomainsChats" @click="clearRightMenu()"></div>

		<div
			style="font-weight: 400;color: rgba(255, 255, 255, 0.85);font-size: 16px;position: absolute;left: 18px;top: 16px;">
			<span style="margin-right: 20px;">采样时间：{{ data.time }}</span>
			<span style="margin-right: 20px;"
				v-if="chartName == '时域波形' || chartName == '频谱分析'">{{ data.rms?'总值：'+data.rms:'' }}
				{{ data.unit ? data.unit.y : '' }}</span>
		
			<span @click="SetBiaozhuVisible = true" title="点击修改标注" style="cursor: pointer;"
				v-if="chartName == '时域波形' || chartName == '频谱分析'">标注{{': '+biaozhuContext}}</span>
		</div>

		<div
			style="font-weight: 400;color: rgba(255, 255, 255, 0.65);font-size: 13px;position: absolute;left: 18px;top:42px;right: 90px;    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;"
			:title="msgText"
		>
			<span style="margin-right: 10px;" v-if="data.measComm">测点描述：{{ data.measComm }}</span>
			<span style="margin-right: 10px;">数据来源：{{ data.dataSrc == 0 ? '圣名科技' : data.dataSrc == 1 ? '西安因联' : data.dataSrc == 2 ? '容知日新' : '' }}</span>
			<span v-for="(item, i) of data.faultFreq" :key="i" style="margin-right: 10px;">
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item }}
			</span>
		</div>
		<div style="color:#ffffff;position: absolute;right: 100px;" v-if="chartName == '时域波形' || chartName == '频谱分析'">
			<span>运行转速：{{ data.rpm }} RPM</span>
		</div>

		<div class="bg99" v-if="isShowBtns">
			<div class="rightBtn" v-if="isShowCursor">
				<div class="btnSome" :class="{ active: this.btnIndex === 1 }" @click="changeBtn(1)">单游</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 2 }" @click="changeBtn(2)">双游</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 3 }" @click="changeBtn(3)">倍频</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 4 }" @click="changeBtn(4)">边频</div>
				<div class="btnSome" :class="{ active: this.markIndex === 2 }" @click="changeMark(5)">清除</div>
			</div>

			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.chatsIndex == '加速度' }" @click="chatsChagne('加速度')">加速度</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '速度' }" @click="chatsChagne('速度')">速度</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '位移' }" @click="chatsChagne('位移')">位移</div>
			</div>

			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">全屏</div>
				<div class="btnSome" v-else @click="exitFullScreen()">退出</div>
			</div>
			
			<div class="rightBtn mt-10">
				<div class="btnSome fontSize12" @click="download()">下载数据</div>
			</div>
			<div ref="rightMenu" class="rightClickMenu" style="display: none;">
				<div>
					<el-button type="text" @click="openSetPoint()">标记</el-button>
					<el-button type="text" @click="openClearOnePoint()">取消标记</el-button>
					<el-button type="text" @click="openClearAllPoint()">取消全部标记</el-button>
				</div>
			</div>
		</div>
		<el-dialog title="标记" :visible.sync="SetPointVisible" width="20%" :modal="false" :show-close="false">
			<el-input style="width: 90%; margin: 0 5%;" focus v-model="pointContext" placeholder="请输入内容"></el-input>
			<span slot="footer">
				<el-button @click="SetPointVisible = false">取 消</el-button>
				<el-button type="primary" @click="setPoint()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
		  title="取消标记"
		  :visible.sync="ClearOnePointVisible"
		  width="20%" :modal="false" :show-close="false">
		  <span style="width: 90%; margin: 0 5%;" >{{'确认删除 ' + onePoint.value + ' 此标记吗？'}}</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="ClearOnePointVisible = false">取 消</el-button>
		    <el-button type="primary" @click="clearOnePointA(onePoint, onePoint.index)">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="取消全部标记"
		  :visible.sync="ClearAllPointVisible"
		  width="20%" :modal="false" :show-close="false">
		  <span style="width: 90%; margin: 0 5%;" >取消全部标记后，所有标记将消失！</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="ClearAllPointVisible = false">取 消</el-button>
		    <el-button type="primary" @click="clearAllPointA()">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog title="标注" :visible.sync="SetBiaozhuVisible" width="20%" :modal="false" :show-close="false">
			<el-input style="width: 90%; margin: 0 5%;" focus v-model="biaozhuContext" placeholder="请输入内容"></el-input>
			<span slot="footer">
				<el-button @click="SetBiaozhuVisible = false">取 消</el-button>
				<el-button type="primary" @click="setBiaozhu()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { beFull, exitFull, watchFull } from 'be-full';
let timeDomainsChats = null;
let option = {};
export default {
	name: 'TimeDomainsChats',
	data() {
		return {
			btnIndex: '',
			markIndex: '',
			chatsIndex: this.activeMeasDef,
			xAxisData: [],
			xAxisArr: [],
			chatsList: [],
			dataZoomStart: 0,
			dataZoomEnd: 100,
			pointContext: '',
			biaozhuContext: '',
			markList: [],
			currentPoint: {},
			seriesPoints: [],
			isFullScreen: false,
			data: {},
			msgText: '',
			tagList: [],
			SetPointVisible: false,
			ClearOnePointVisible:false,
			ClearAllPointVisible:false,
			onePoint:'',
			SetBiaozhuVisible: false,
			biaozhuId: null
		};
	},
	props: {
		chatsData: {
			type: Object,
			default() {
				return {};
			}
		},
		activeMeasDef: {
			type: String,
			default() {
				return '';
			}
		},
		isShowCursor: {
			type: Boolean,
			default() {
				return true;
			}
		},
		isShowBtns: {
			type: Boolean,
			default() {
				return true;
			}
		},
		chartName: {
			type: String,
			default() {
				return '';
			}
		}
	},
	watch: {
		activeMeasDef(val) {
			this.chatsIndex = val;
		},
		chatsData(val) {
			this.chatsList = val;
			this.init();
			this.getBiaozhu()
		}
	},
	mounted() {
		let that = this;
		watchFull(this.$refs.chartDiv, ifs => {
			if (timeDomainsChats) {
				timeDomainsChats.resize();
			}
			if(!ifs){
				that.isFullScreen = false;
			}
		});
	},
	beforeDestroy() {
		if (timeDomainsChats) {
			timeDomainsChats.dispose();
			timeDomainsChats = null;
		}
		option = {};
	},
	methods: {
		//设置标注
		setBiaozhu() {
			let param = {
				remark: this.biaozhuContext,
				measTime: this.data.time,
				measId: this.data.measId,
			}
			if (this.biaozhuId) {
				param.id = this.biaozhuId
			}
			this.$postJson('/backend-api/eqp/measRemark/addData', param).then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return
				}
				this.$message.success('保存成功');
			})
		
			this.SetBiaozhuVisible = false;
		},
		
		getBiaozhu() {
			this.biaozhuId = null;
			this.biaozhuContext ='';
			let param = {
				measTime: this.data.time,
				measId: this.data.measId,
			}
			this.$post('/backend-api/eqp/measRemark/getDataByMeasAndTime', param).then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return
				}
				if (data.data) {
					this.biaozhuId = data.data.id
					this.biaozhuContext = data.data.remark;
				}
		
			})
		},
		//全屏
		fullScreen() {
			this.isFullScreen = true;
			beFull(this.$refs.chartDiv);
		},
		//退出全屏
		exitFullScreen() {
			this.isFullScreen = false;
			exitFull();
		},
		//关闭右键按钮
		clearRightMenu() {
			this.$refs.rightMenu.style['display'] = 'none';
			this.$refs.rightMenu.style['left'] = '9999px';
			this.$refs.rightMenu.style['top'] = '9999px';
		},
		//打开标记弹窗
		openSetPoint() {
			this.clearRightMenu();
			this.SetPointVisible = true;
			// this.$prompt('', '标记', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	inputValue: this.pointContext
			// })
			// 	.then(obj => {
			// 		this.pointContext = obj.value;
			// 		this.setPoint();
			// 	})
			// 	.catch(() => {});
		},
		//下载原始数据
		download(){
			let param = {
				time:this.data.time,
				meas_id:this.data.measId,
			}
			this.$download('/data-api/open/vib/download/excel', param).then(res => {
				let blob = new Blob([res], {
					type: "application/vnd.ms-excel"
				})
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.download = '('+this.data.time+')'+this.data.root + '.xlsx';
				link.href = url;
				document.body.appendChild(link);
				link.click();
			})
		},
		//打开取消标记弹窗
		openClearOnePoint() {
			if (this.chatsList.type == '时域波形') {
				for (let i = 0, length = option.series[0].markPoint.data.length; i < length; i++) {
					let item = option.series[0].markPoint.data[i];
					if (item.xAxis - this.currentPoint.name <= 5 && item.xAxis - this.currentPoint.name >= -5) {
						this.clearRightMenu();
						// this.$confirm('确认删除' + item.value + '此标记吗？', '取消标记', {
						// 	confirmButtonText: '确定',
						// 	cancelButtonText: '取消',
						// 	type: 'warning'
						// })
						// 	.then(() => {
						// 		this.clearOnePointss(item,i);
						// 	})
						// 	.catch(() => {});
						this.ClearOnePointVisible =true;
						this.onePoint = item;
						this.onePoint.index = i;
						return;
					}
				}
			}else{
				for (var i in this.markList) {
					var item = this.markList[i];
					var flag = false;
					for (var i2 in item.markPoint) {
						var item2 = item.markPoint[i2];
						if (item2.xAxis - this.currentPoint.name < 1 && item2.xAxis - this.currentPoint.name > -1 && item.seriesIndex == this.currentPoint.seriesIndex) {
							this.clearRightMenu();
							// this.$confirm('确认删除' + item2.value + '此标记吗？', '取消标记', {
							// 	confirmButtonText: '确定',
							// 	cancelButtonText: '取消',
							// 	type: 'warning'
							// })
							// 	.then(() => {
							// 		this.clearOnePoint();
							// 	})
							// 	.catch(() => {});
							this.ClearOnePointVisible =true;
							this.onePoint = item2;
							this.onePoint.index = i;
							return;
						}
					}
				}
			}
		},
		//打开取消全部标记弹窗
		openClearAllPoint() {
			this.clearRightMenu();
			this.ClearAllPointVisible =true;
			// this.$confirm('取消全部标记后，所有标记将消失！', '取消全部标记', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// })
			// 	.then(() => {
			// 		if (this.chatsList.type != '时域波形') {
			// 			this.clearAllPoint();
			// 		}else{
			// 			this.clearAllPointss()
			// 		}
			// 	})
			// 	.catch(() => {});
		},
		chatsChagne(chatsIndex) {
			this.chatsIndex = chatsIndex;
			this.$emit('chatsChagne', chatsIndex);
		},
		initOption() {
			var grids = [];
			var xAxis = [];
			var yAxis = [];
			var series = [];
			var xAxisIndex = [];
			var titles = [];
			var length = this.chatsList.data.length;
			for (var i = 0; i < length; i++) {
				grids.push({
					top: i * (90 / length) + 8 + '%',
					left: '30px',
					right: '80px',
					bottom: (length - 1 - i) * (90 / length) + 10 + '%',
					containLabel: true
				});
				if (this.chatsList.data.name) {
					titles.push({
						left: 'center',
						top: i * (90 / length) + 5 + '%',
						text: this.chatsList.data.name[i]
					});
				}
				xAxis.push({
					name: this.chatsList.data.unit[i].x,
					nameTextStyle: {
						color: '#D4D5D7'
					},
					// minInterval: 1,
					type: 'category',
					//x轴在 grid 区域中的分隔线。
					splitLine: {
						show: true,
						lineStyle: {
							color: '#384049',
							type: 'dashed'
						}
					},
					//x坐标轴轴线相关设置。
					axisLine: {
						show: true,
						lineStyle: {
							color: '#384049'
						},
						onZero: false
					},
					// 坐标轴刻度标签的相关设置。
					axisLabel: {
						// rotate: -30,
						interval: 'auto',
						color: '#D4D5D7'
					},
					max: value => {
						return value.max;
					},
					gridIndex: i
				});
				let yax = {
					name: this.chatsList.data.unit[i].y,
					nameTextStyle: {
						color: '#D4D5D7'
					},
					type: 'value',
					splitLine: {
						show: true,
						lineStyle: {
							color: '#384049',
							type: 'dashed'
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#384049'
						},
						onZero: false
					},
					axisLabel: {
						color: '#D4D5D7'
					},
					gridIndex: i
				};
				let obj = {
					data: this.chatsData.data.series[i],
					type: 'line',
					symbolSize: 1,
					showSymbol: false,
					showAllSymbol: false,
					xAxisIndex: i,
					yAxisIndex: i,
					itemStyle: {
						normal: {
							color: '#15B931',
							lineStyle: {
								width: 0.8, // 0.1的线条是非常细的了
								color: '#15B931'
							}
						}
					},
					markPoint: {}
				};
				if ((this.chartName == '温度' || this.chartName == '实际电流') && i == 0) {
					let warnData = [];
					let warnColor = `warn_color`;
					let warnValue = `warn_value`;
					let warnName = `warn_name`;
					warnData.push({
						name: this.chatsData.data[warnName],
						yAxis: this.chatsData.data[warnValue],
						lineStyle: {
							type: 'solid',
							color: this.chatsData.data[warnColor]
						},
						label: {
							color: '#ffffff',
							formatter: params => {
								return params.name + ':' + params.value;
							}
						}
					});
					obj.markLine = {
						data: warnData
					};
					if (this.chartName == '温度') {
						yax.max = parseInt(this.chatsData.data[warnValue]) + 20;
					} else if (this.chartName == '实际电流') {
						yax.max = parseInt(this.chatsData.data[warnValue]) + 200;
					}
				}
				yAxis.push(yax);
				series.push(obj);
				xAxisIndex.push(i);
			}

			if (this.chatsList.type == '时域波形') {
				let PointArr = {
					itemStyle: {
						color: '#66ccff'
					},
					label: {
						color: '#ffffff'
					},
					data: []
				};
				if (this.chatsList.data.tags.length > 0) {
					for (let es = 0, lenes = this.chatsList.data.tags.length; es < lenes; es++) {
						PointArr.data.push({
							value: this.chatsList.data.tags[es].value,
							xAxis: String(this.chatsList.data.tags[es].x),
							yAxis: String(this.chatsList.data.tags[es].y),
							ids: String(this.chatsList.data.tags[es].id)
						});
					}
				}
				series[0].markPoint = PointArr;
			}
			option = {
				backgroundColor: '#000B17',
				title: titles,
				tooltip: {
					confine: true, // 加入这一句话
					trigger: 'axis',
					backgroundColor: 'rgba(34, 34, 34, 0.5)',
					borderWidth: 0,
					textStyle: {
						color: 'rgba(255, 255, 255, 1.0)'
					},
					axisPointer: {
						animation: false
					},
					position: function(pos, params, el, elRect, size) {
						var obj = {
							top: 10
						};
						obj['left'] = pos[0] + 10;
						obj['top'] = pos[1] - 60;
						return obj;
					},
					formatter: param => {
						let text = [];
						text.push(this.chatsData.data.tooltip[0].x + '：' + param[0].value[0] + this.chatsData.data.unit[0].x + '<br/>');
						if (param.length == 1) {
							text.push(this.chatsData.data.tooltip[0].y + '：' + param[0].value[1] + this.chatsData.data.unit[0].y + '<br/>');
						} else {
							for (let i = 0; i < param.length; i++) {
								let item = param[i];
								text.push(this.chatsData.data.tooltip[0].y + (i + 1) + '：' + item.value[1] + this.chatsData.data.unit[0].y + '<br/>');
							}
						}
						return text.join('');
					}
				},
				toolbox: {
					show: true,
					top: 20,
					right: 20,
					zlevel: 899,
					feature: {
						dataZoom: {
							yAxisIndex: 'none',
							brushStyle: {
								color: "rgba(35, 136, 255, 0.1)",
								shadowBlur: 2
							}
						},
						restore: {}
					}
				},
				grid: grids,
				xAxis: xAxis,
				yAxis: yAxis,
				series: series,
				axisPointer: {
					type: 'line',
					link: [
						{
							xAxisIndex: 'all'
						}
					],
					label: {
						show: false
					}
				},
				dataZoom: [
					{
						show: true,
						realtime: true,
						start: this.dataZoomStart,
						end: this.dataZoomEnd,
						xAxisIndex: xAxisIndex,
						filterMode: 'empty',
					},
					{
						type: 'inside',
						realtime: true,
						xAxisIndex: xAxisIndex
					}
				]
			};
		},
		init() {
			this.data = this.chatsData.data;
			this.msgText = '';
			this.msgText = '测点描述：' + (this.data.comm ? this.data.comm : '') + ' ';
			this.msgText += '数据来源：';
			if (this.data.type == 0) {
				this.msgText += '圣名科技';
			} else if (this.data.type == 1) {
				this.msgText += '西安因联';
			} else if (this.data.type == 2) {
				this.msgText += '容知日新';
			}
			this.msgText += ' ';
			if (this.data.faultFreq) {
				for (let item of this.data.faultFreq) {
					this.msgText += item + ' ';
				}
			}
			this.initOption();
			timeDomainsChats = this.$echarts.init(this.$refs.timeDomainsChats);
			timeDomainsChats.setOption(option, true);
			if (this.chatsList.type != '时域波形') {
				this.clearAllPoint();
			}
			//缩放时记录缩放的范围
			timeDomainsChats.on('dataZoom', event => {
				if (event.batch) {
					this.dataZoomStart = event.batch[0].start;
					this.dataZoomEnd = event.batch[0].end;
				} else {
					this.dataZoomStart = event.start;
					this.dataZoomEnd = event.end;
				}
			});
			//右键弹出菜单，支持点击空白处
			timeDomainsChats.getZr().on('contextmenu', params => {
				this.clickZr(params, () => {
					//打开右键菜单
					this.pointContext = this.currentPoint.name;
					this.$refs.rightMenu.style['display'] = 'block';
					this.$refs.rightMenu.style['left'] = params.offsetX + 30 + 'px';
					this.$refs.rightMenu.style['top'] = params.offsetY + 30 + 'px';
				});
			});
		},
		//点击空白处设置数据
		clickZr(params, func) {
			const pointInPixel = [params.offsetX, params.offsetY];
			this.seriesPoints = [];
			//对series遍历
			for (var i = 0; i < option.series.length; i++) {
				let index = timeDomainsChats.convertFromPixel(
					{
						seriesIndex: i
					},
					[params.offsetX, params.offsetY]
				);
				let xIndex = index[0];
				let yIndex = index[1];
				let op = option;
				// 获取当前点击位置对应折线上的数据
				var xData = op.series[i].data[xIndex][0];
				var yData = op.series[i].data[xIndex][1];
				//记录所有坐标系的点
				var pointObj = {
					name: xData,
					value: yData,
					xIndex: xIndex,
					yIndex: yIndex,
					seriesIndex: i
				};
				this.seriesPoints.push(pointObj);
				//单独记录所点坐标系的点
				if (
					timeDomainsChats.containPixel(
						{
							seriesIndex: i
						},
						pointInPixel
					)
				) {
					this.currentPoint.name = xData;
					this.currentPoint.value = yData;
					this.currentPoint.xIndex = xIndex;
					this.currentPoint.yIndex = yIndex;
					this.currentPoint.seriesIndex = i;
				}
			}
			func();
		},
		//设置标记
		setPoint() {
			option.dataZoom[0].start = this.dataZoomStart;
			option.dataZoom[0].end = this.dataZoomEnd;
			
			if (this.chatsList.type == '时域波形') {
				let tag = {
					meas_def: this.chatsData.meas_def,
					meas_id: this.chatsData.meas_id,
					time: this.data.time,
					value: this.pointContext,
					x: this.currentPoint.name,
					y: this.currentPoint.value,
					type:this.chatsData.type
				};
				this.$postData('/backend-api/vib/spectrum/tag/add', tag).then(res => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
						let data=[]
						this.tagList =[]
						this.chatsList.data.tags = res.data;
						if (res.data.length > 0) {
							for (let i = 0, lenes = res.data.length; i < lenes; i++) {
								this.tagList.push(res.data[i].id);
								data.push({
									value: res.data[i].value,
									xAxis: String(res.data[i].x),
									yAxis: String(res.data[i].y),
									ids: String(res.data[i].id)
								});
							}
						}
						option.series[0].markPoint.data =data;
						timeDomainsChats.setOption(option, true);
						this.SetPointVisible = false;
						return;
					}
					this.$message.error(res.msg);
				})
			}else{
				const markPoint = {
					value: this.pointContext,
					xAxis: String(this.currentPoint.name),
					yAxis: String(this.currentPoint.value)
				};
				var flag = true;
				//添加之前先检查是否存在
				for (var item of this.markList) {
					if (item.seriesIndex == this.currentPoint.seriesIndex) {
						item.markPoint.push(markPoint);
						flag = false;
						break;
					}
				}
				//flag为true，表示数组中不存在
				if (flag) {
					var markObj = {
						markPoint: [markPoint],
						seriesIndex: this.currentPoint.seriesIndex
					};
					this.markList.push(markObj);
				}
				for (var item of this.markList) {
					option.series[item.seriesIndex].markPoint = {
						itemStyle: {
							color: '#66ccff'
						},
						label: {
							color: '#ffffff'
						},
						data: item.markPoint
					};
				}
				timeDomainsChats.setOption(option, true);
				this.SetPointVisible = false;
			}
		},
		clearOnePointA(item,i){
			if (this.chatsList.type == '时域波形') {
				this.clearOnePointss(item,i)
			}else{
				this.clearOnePoint()
			}
		},
		clearAllPointA(){
			if (this.chatsList.type == '时域波形') {
				this.clearAllPointss()
			}else{
				this.clearAllPoint()
			}
		},
		//时域波形清除单个标记
		clearOnePointss(item,i) {
			option.dataZoom[0].start = this.dataZoomStart;
			option.dataZoom[0].end = this.dataZoomEnd;
			this.$postData('/backend-api/vib/spectrum/tag/remove-one?id='+item.ids).then(res => {
				if (res.code == 1000) {
					option.series[0].markPoint.data.splice(i, 1);
					timeDomainsChats.setOption(option, true);
					this.$message.success(res.msg);
					this.ClearOnePointVisible =false;
					return;
				}
				this.$message.error(res.msg);
			});
			
		},
		//时域波形清除所有标记
		clearAllPointss() {
			option.dataZoom[0].start = this.dataZoomStart;
			option.dataZoom[0].end = this.dataZoomEnd;
			let str = this.tagList.join(',');
			this.$postData('/backend-api/vib/spectrum/tag/remove-batch?ids=' + str).then(res => {
				if (res.code == 1000) {
					this.$message.success(res.msg);
					option.series[0].markPoint.data = [];
					timeDomainsChats.setOption(option, true);
					this.ClearAllPointVisible =false;
					return;
				}
				this.$message.error(res.msg);
			});
		},
		//清除单个标记
		clearOnePoint() {
			option.dataZoom[0].start = this.dataZoomStart;
			option.dataZoom[0].end = this.dataZoomEnd;
			//找出是否存在这个点，存在就删除
			for (var i in this.markList) {
				var item = this.markList[i];
				var flag = false;
				for (var i2 in item.markPoint) {
					var item2 = item.markPoint[i2];
					if (item2.xAxis - this.currentPoint.name < 1 && item2.xAxis - this.currentPoint.name > -1 && item.seriesIndex == this.currentPoint.seriesIndex) {
						item.markPoint.splice(i2, 1);
					}
					if (item.markPoint.length == 0) {
						flag = true;
					}
				}
				if (flag) {
					this.markList.splice(i, 1);
				}
			}
			//重新画标记
			for (var item of this.markList) {
				option.series[item.seriesIndex].markPoint = {
					itemStyle: {
						color: '#66ccff'
					},
					label: {
						color: '#ffffff'
					},
					data: item.markPoint
				};
			}
			timeDomainsChats.setOption(option, true);
			this.ClearOnePointVisible =false;
		},
		//清除所有标记
		clearAllPoint() {
			option.dataZoom[0].start = this.dataZoomStart;
			option.dataZoom[0].end = this.dataZoomEnd;
			this.markList = [];
			for (var item of option.series) {
				item.markPoint = {
					itemStyle: {
						color: '#66ccff'
					},
					label: {
						color: '#ffffff'
					},
					data: []
				};
			}
			timeDomainsChats.setOption(option, true);
			this.ClearAllPointVisible =false;
		},
		resetBaseLine() {
			if (this.xAxisData.length > 0) {
				if (this.xAxisData[0].length > 0) {
					for (var i = 0; i < this.seriesPoints.length; i++) {
						let obj = this.xAxisData[i][0];
						this.xAxisData[i] = [];
						this.xAxisData[i].push(obj);
						var series = option.series[i];
						var index = i;
						series.markLine = {
							lineStyle: {
								normal: {
									color: 'rgba(255, 38, 38, 1)'
								}
							},
							label: {
								color: '#ffffff',
								formatter: params => {
									return '(x=' + params.data.xValue + ' , y=' + params.data.yValue + ')';
								}
							},
							data: this.xAxisData[i]
						};
					}
				}
			} else {
				this.xAxisData = [];
			}
			if (this.xAxisArr.length > 0) {
				if (this.xAxisArr[0].length > 0) {
					for (var i = 0; i < this.seriesPoints.length; i++) {
						let obj = this.xAxisArr[i][0];
						this.xAxisArr[i] = [];
						this.xAxisArr[i].push(obj);
					}
				}
			} else {
				this.xAxisArr = [];
			}
		},
		changeBtn(btnIndex) {
			this.btnIndex = btnIndex;
			//切换时只显示基线
			this.resetBaseLine();
			if (btnIndex == 3) {
				for (var i = 0; i < this.seriesPoints.length; i++) {
					var item = this.seriesPoints[i];
					var arr = [];
					for (let j = 1; j < 6; j++) {
						let indexVal = this.currentPoint.xIndex * j;
						if (indexVal < option.series[i].data.length) {
							const xAxisObj = {
								xIndex: this.currentPoint.xIndex,
								yIndex: this.currentPoint.yIndex,
								xValue: this.currentPoint.name,
								yValue: item.value,
								xAxis: String(option.series[i].data[indexVal][0]),
								lineStyle: {
									type: j == 1 ? 'solid' : 'dashed'
								}
							};
							arr.push(xAxisObj);
						}
					}
					this.xAxisData = [];
					this.xAxisData.push(arr);
					var series = option.series[i];
					series.markLine = {
						lineStyle: {
							normal: {
								color: 'rgba(255, 38, 38, 1)'
							}
						},
						label: {
							color: '#ffffff',
							formatter: params => {
								if (params.dataIndex == 0) {
									return '(x=' + params.data.xValue + '，y=' + params.data.yValue + ')';
								} else {
									return '';
								}
							}
						},
						data: this.xAxisData[i]
					};
				}
			}
			timeDomainsChats.setOption(option, true);
			timeDomainsChats.getZr().off('click');
			timeDomainsChats.getZr().on('click', params => {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				this.clickZr(params, () => {
					switch (this.btnIndex) {
						case 1:
							for (var i = 0; i < this.seriesPoints.length; i++) {
								var item = this.seriesPoints[i];
								var xAxisData1 = {};
								xAxisData1 = {
									xIndex: this.currentPoint.xIndex,
									yIndex: this.currentPoint.yIndex,
									xValue: this.currentPoint.name,
									yValue: item.value,
									xAxis: String(this.currentPoint.name),
									lineStyle: {
										type: 'solid'
									}
								};
								if (this.xAxisData.length <= i) {
									var arr = [];
									arr.push(xAxisData1);
									this.xAxisData.push(arr);
									this.xAxisArr = [];
									this.xAxisArr.push([this.currentPoint.xIndex]);
								} else {
									this.xAxisData[i] = [];
									this.xAxisData[i].push(xAxisData1);
								}
								var series = option.series[i];
								var index = i;
								series.markLine = {
									lineStyle: {
										normal: {
											color: 'rgba(255, 38, 38, 1)'
										}
									},
									label: {
										color: '#ffffff',
										formatter: params => {
											return '(x=' + params.data.xValue + ' , y=' + params.data.yValue + ')';
										}
									},
									data: this.xAxisData[i]
								};
							}
							break;
						case 2:
							//当前每一个series的游标
							for (var i = 0; i < this.seriesPoints.length; i++) {
								var item = this.seriesPoints[i];
								var xAxisObj = {
									xIndex: this.currentPoint.xIndex,
									yIndex: this.currentPoint.yIndex,
									xValue: this.currentPoint.name,
									yValue: item.value,
									xAxis: String(this.currentPoint.name),
									flag: false,
									lineStyle: {
										type: 'solid'
									}
								};
								if (this.xAxisData.length <= i) {
									var arr = [];
									arr.push(xAxisObj);
									this.xAxisData.push(arr);
									this.xAxisArr = [];
									this.xAxisArr.push([this.currentPoint.xIndex]);
								} else if (this.xAxisData[i].length == 1) {
									this.xAxisData[i][0].flag = true;
									this.xAxisData[i].push(xAxisObj);
									var t = Math.abs(this.xAxisData[i][0].xValue - this.xAxisData[i][1].xValue).toFixed(2);
									var f = (1000 / t).toFixed(2);
									var amp = Math.abs(this.xAxisData[i][0].yValue - this.xAxisData[i][1].yValue).toFixed(2);
									xAxisObj = {
										xIndex: this.currentPoint.xIndex,
										yIndex: this.currentPoint.yIndex,
										xValue: this.currentPoint.name,
										yValue: item.value,
										xAxis: String(this.currentPoint.name),
										t,
										f,
										amp,
										flag: true,
										lineStyle: {
											type: 'dashed'
										}
									};
									this.xAxisData[i][1] = xAxisObj;
								} else if (this.xAxisData[i].length == 2) {
									this.xAxisData[i] = [];
									this.xAxisData[i].push(xAxisObj);
									this.xAxisArr = [];
									this.xAxisArr.push([this.currentPoint.xIndex]);
								}
								var series = option.series[i];
								series.markLine = {
									lineStyle: {
										normal: {
											color: 'rgba(255, 38, 38, 1)'
										}
									},
									label: {
										color: '#ffffff',
										formatter: params => {
											if (params.data.flag) {
												if (params.dataIndex == 1) {
													return '(△t=' + params.data.t + ',△f=' + params.data.f + ',△Amp=' + params.data.amp + ')';
												} else {
													return '';
												}
											} else {
												return '(x=' + params.data.xValue + ',y=' + params.data.yValue + ')';
											}
										}
									},
									data: this.xAxisData[i]
								};
							}
							break;
						case 3:
							this.xAxisData = [];
							for (var i = 0; i < this.seriesPoints.length; i++) {
								var item = this.seriesPoints[i];
								var arr = [];
								for (let j = 1; j < 6; j++) {
									let indexVal = this.currentPoint.xIndex * j;
									if (indexVal < option.series[i].data.length) {
										const xAxisObj = {
											xIndex: this.currentPoint.xIndex,
											yIndex: this.currentPoint.yIndex,
											xValue: this.currentPoint.name,
											yValue: item.value,
											xAxis: String(option.series[i].data[indexVal][0]),
											lineStyle: {
												type: j == 1 ? 'solid' : 'dashed'
											}
										};
										arr.push(xAxisObj);
									}
								}
								this.xAxisData.push(arr);
								this.xAxisArr = [];
								this.xAxisArr.push([this.currentPoint.xIndex]);
								var series = option.series[i];
								series.markLine = {
									lineStyle: {
										normal: {
											color: 'rgba(255, 38, 38, 1)'
										}
									},
									label: {
										color: '#ffffff',
										formatter: params => {
											if (params.dataIndex == 0) {
												return '(x=' + params.data.xValue + ',y=' + params.data.yValue + ')';
											} else {
												return '';
											}
										}
									},
									data: this.xAxisData[i]
								};
							}
							break;
						case 4:
							let xAxis = String(this.currentPoint.name);
							let indexCalc = '';
							let xAxisCalc = 1;
							for (var i = 0; i < this.seriesPoints.length; i++) {
								var item = this.seriesPoints[i];
								var xAxisObj = {
									xIndex: this.currentPoint.xIndex,
									yIndex: this.currentPoint.yIndex,
									xValue: this.currentPoint.name,
									yValue: item.value,
									xAxis: String(this.currentPoint.name),
									flag: false,
									lineStyle: {
										type: 'solid'
									}
								};
								if (this.xAxisData.length <= i) {
									var arr = [];
									arr.push(xAxisObj);
									this.xAxisData.push(arr);
									this.xAxisArr = [];
									this.xAxisArr.push([this.currentPoint.xIndex]);
								} else if (this.xAxisArr[i].length == 1) {
									this.xAxisArr[i].push(this.currentPoint.xIndex);
									this.xAxisData[i][0].flag = true;
									xAxisObj.flag = true;
									xAxisObj.lineStyle.type = 'dashed';
									this.xAxisData[i].push(xAxisObj);
									indexCalc = this.xAxisArr[i][1] - this.xAxisArr[i][0];
									xAxisCalc = Math.abs(option.series[i].data[this.xAxisArr[i][1]][0] - option.series[i].data[this.xAxisArr[i][0]][0]);
									this.xAxisData[i][0].f = Math.abs(this.xAxisData[i][0].xValue - this.xAxisData[i][1].xValue).toFixed(2);
									for (let j = 1; j < 4; j++) {
										let subtractValue = Number(this.xAxisArr[i][0]) - Number(indexCalc * j);
										let subtractValue1 = Number(this.xAxisArr[i][0]) + Number(indexCalc * j);
										if (
											0 < subtractValue &&
											subtractValue < option.series[i].data.length &&
											0 < subtractValue1 &&
											subtractValue1 < option.series[i].data.length
										) {
											const xAxisObj = {
												xIndex: this.currentPoint.xIndex,
												yIndex: this.currentPoint.yIndex,
												xAxis: String(option.series[i].data[subtractValue][0]),
												flag: true,
												lineStyle: {
													type: 'dashed'
												}
											};
											const xAxisObj1 = {
												xIndex: this.currentPoint.xIndex,
												yIndex: this.currentPoint.yIndex,
												xAxis: String(option.series[i].data[subtractValue1][0]),
												flag: true,
												lineStyle: {
													type: 'dashed'
												}
											};
											this.xAxisData[i].push(xAxisObj, xAxisObj1);
										}
									}
								} else {
									this.xAxisArr[i] = [this.currentPoint.xIndex];
									this.xAxisData[i] = [xAxisObj];
								}
								var series = option.series[i];
								series.markLine = {
									lineStyle: {
										normal: {
											color: 'rgba(255, 38, 38, 1)'
										}
									},
									label: {
										color: '#ffffff',
										formatter: params => {
											if (params.data.flag) {
												if (params.dataIndex == 0) {
													return '(x=' + params.data.xValue + ',y=' + params.data.yValue + ',△f=' + params.data.f + ')';
												} else {
													return '';
												}
											} else {
												return '(x=' + params.data.xValue + ',y=' + params.data.yValue + ')';
											}
										}
									},
									data: this.xAxisData[i]
								};
							}
							break;
					}
					timeDomainsChats.setOption(option, true);
				});
			});
		},
		changeMark(val) {
			this.seriesPoints = [];
			this.xAxisData = [];
			this.xAxisArr = [];
			this.btnIndex = val;
			timeDomainsChats.getZr().off('click');
			if (val == 5) {
				this.init();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	.fontSize12{
		font-size: 12px;
	}
.chartDiv {
	.active {
		background-color: #2388ff;
	}

	.timeDomainsChats {
		height: 85vh;
		width: 100%;
		background-color: #000b17;
	}

	.timeDomainsChats.fsCharts {
		height: 100vh;
		width: 100%;
		background-color: #000b17;
	}

	.bg99 {
		background-color: #000b17;
	}

	.btnSome {
		height: 60px;
		width: 60px;
		color: #fff;
		text-align: center;
		line-height: 60px;
		cursor: pointer;
	}

	.btnSome:hover {
		opacity: 1;
		color: #aaa;
	}

	// .rightChange {
	//    background-color: #596067;
	// }
	.rightBtn {
		background-color: #596067;
		border-radius: 8px;
		overflow: hidden;
	}
}

.rightClickMenu {
	/*这个样式不写，右键弹框会一直显示在画布的左下角*/
	position: absolute;
	background-color: rgb(255, 255, 255, 0.8);
	color: rgb(0, 0, 0, 1);
	border-radius: 5px;
	left: -99999px;
	top: -999999px;
}

.rightClickMenu > div > .el-button {
	cursor: pointer;
	margin: 0;
	padding: 5px 10px;
	font-size: 14px;
	display: block;
	width: 100%;
	color: #000000;
}

.rightClickMenu > div > .el-button:hover {
	background-color: rgb(64, 158, 255, 0.8);
}
::v-deep .el-dialog{
	background-color: #ffffff !important;
	border-color: #FFFFFF !important;
}
::v-deep .el-dialog__header{
	text-align: center;
	color: #000000;
	padding: 10px;
}
::v-deep .el-dialog__title {
	color: #000000 !important;
}
::v-deep .el-input__inner{
	border-color:#409eff !important;
}
::v-deep .el-dialog__footer{
	padding: 10px 5%;
	border-color: none !important;
}
::v-deep .el-button{
	border-color: transparent !important;
}
</style>
