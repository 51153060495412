<template>
	<div class="flex chartDiv" ref="chartDiv">
		<div class="tempChats" :class="isFullScreen?'fsCharts':''" ref="tempChats"></div>

		<div
			style="font-weight: 400;color: rgba(255, 255, 255, 0.85);font-size: 16px;position: absolute;left: 18px;top: 16px;">
			<img src="@assets/osImg/monitoring/tempBack.png" style="width:16px;height:16px;cursor:pointer"
				@click="$emit('tempBack')" v-if="canBack" />
			<span v-if="chartName=='时域波形'||chartName=='频谱分析'">总值：{{data.rms}} {{data.unit?data.unit.y:""}}</span>
		</div>
		<div style="font-weight: 400;color: rgba(255, 255, 255, 0.65);font-size: 13px;position: absolute;left: 18px;top:42px;right: 90px;
			white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;" :title="msgText">
			<span style="margin-right: 10px;" v-if="data.measComm">测点描述：{{data.measComm}} </span>
			<span
				style="margin-right: 10px;">数据来源：{{data.dataSrc==0?'圣名科技':(data.dataSrc==1?'西安因联':(data.dataSrc==2?'容知日新':''))}}
			</span>
			<span v-for="item of data.fault_freq"
				style="margin-right: 10px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item}}</span>
		</div>

		<div class="bg99">

			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">
					全屏
				</div>
				<div class="btnSome" v-else @click="exitFullScreen()">
					退出
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	let tempChats = null;
	let option={};
	export default {
		name: "tempChats",
		data() {
			return {
				btnIndex: "",
				markIndex: "",
				chatsIndex: this.activeMeasDef,
				xAxisData: [],
				xAxisArr: [],
				chatsList: [],
				dataZoomStart: 0,
				dataZoomEnd: 100,
				pointContext: "",
				markList: [],
				currentPoint: {},
				seriesPoints: [],
				isFullScreen: false,
				data: {},
				msgText: ""
			};
		},
		props: {
			chatsData: {
				type: Object,
				default () {
					return {};
				}
			},
			activeMeasDef: {
				type: String,
				default () {
					return "";
				}
			},
			isShowCursor: {
				type: Boolean,
				default () {
					return true;
				}
			},
			isShowBtns: {
				type: Boolean,
				default () {
					return true;
				}
			},
			chartName: {
				type: String,
				default () {
					return "";
				}
			},
			canBack: {
				type: Boolean,
				default () {
					return false;
				}
			}
		},
		watch: {
			activeMeasDef(val) {
				this.chatsIndex = val;
			},
			chatsData(val) {
				this.chatsList = val;
				this.init();
			},
		},
		mounted() {
			let that = this;
			watchFull(this.$refs.chartDiv, ifs => {
				if (tempChats) {
					tempChats.resize();
				}
				if(!ifs){
					that.isFullScreen = false;
				}
			});
		},
		beforeDestroy() {
			if (tempChats) {
				tempChats.dispose();
				tempChats = null;
			}
			option = {};
		},
		methods: {
			//全屏
			fullScreen() {
				this.isFullScreen = true;
				beFull(this.$refs.chartDiv);
			},
			//退出全屏
			exitFullScreen() {
				this.isFullScreen = false;
				exitFull();
			},
			chatsChagne(chatsIndex) {
				this.chatsIndex = chatsIndex;
				this.$emit('chatsChagne', chatsIndex)
			},
			initOption() {
				option = {
					backgroundColor: '#000B17',
					tooltip: {
						confine: true, // 加入这一句话
						trigger: 'axis',
						backgroundColor: 'rgba(34, 34, 34, 0.5)',
						borderWidth: 0,
						textStyle: {
							color: 'rgba(255, 255, 255, 1.0)'
						},
						axisPointer: {
							animation: false
						},
						position: function(pos, params, el, elRect, size) {
							var obj = {
								top: 10
							};
							obj['left'] = pos[0] + 10;
							obj['top'] = pos[1] - 60;
							return obj;
						},
						formatter: (param) => {
							param = param[0];
							return [
								this.chatsData.data.tooltip.x + "：" + param.name +
								"<br/>",
								this.chatsData.data.tooltip.y + "：" + param.value + this.chatsData.data.unit.y
							].join('');
						}
					},
					grid: {
						top: "8%",
						left: "30px",
						right: "100px",
						bottom: "10%",
						containLabel: true
					},
					xAxis: {
						name: this.chatsList.data.unit.x,
						nameTextStyle: {
							color: "#D4D5D7"
						},
						data: this.chatsList.data.xData[0].data,
						// minInterval: 1,
						type: "category",
						//x轴在 grid 区域中的分隔线。
						splitLine: {
							show: true,
							lineStyle: {
								color: "#384049",
								type: 'dashed'
							}
						},
						//x坐标轴轴线相关设置。
						axisLine: {
							show: true,
							lineStyle: {
								color: "#384049"
							},
							onZero: false
						},
						// 坐标轴刻度标签的相关设置。
						axisLabel: {
							// rotate: -30,
							interval: 'auto',
							color: "#D4D5D7"
						},
					},
					yAxis: {
						name: this.chatsList.data.unit ? this.chatsList.data.unit.y : "",
						nameTextStyle: {
							color: "#D4D5D7"
						},
						type: "value",
						splitLine: {
							show: true,
							lineStyle: {
								color: "#384049",
								type: 'dashed'
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#384049"
							},
							onZero: false
						},
						axisLabel: {
							color: "#D4D5D7"
						},
					},
					series: {
						data: this.chatsList.data.yData[0].data,
						type: "line",
						symbolSize: 5,
						itemStyle: {
							normal: {
								color: '#15B931',
								lineStyle: {
									width: 0.8, // 0.1的线条是非常细的了
									color: '#15B931',
								}
							}
						},
					},
					dataZoom: [{
							show: true,
							realtime: true,
							start: this.dataZoomStart,
							end: this.dataZoomEnd,
							filterMode: 'empty',
						},
						{
							type: "inside",
							realtime: true,
						},
					],
				};
				if ((this.chartName == '温度' || this.chartName == '实际电流')) {
					let warnData = [];
					let warnColor = `alarmColor`;
					let warnValue = `alarmValue`;
					let warnName = `alarmName`;
					let warnMax = `max`;
					warnData.push({
						name: this.chatsData.data.yData[0][warnName],
						yAxis: this.chatsData.data.yData[0][warnValue],
						lineStyle: {
							type: 'solid',
							color: this.chatsData.data.yData[0][warnColor]
						},
						label: {
							color: "#ffffff",
							formatter: (params) => {
								return params.name + ":" + params.value;
							}
						}
					}, );
					option.series.markLine = {
						data: warnData,
					}
					if (this.chartName == '温度') {
						option.yAxis.max = parseInt(this.chatsData.data.yData[0][warnMax]);
					} else if (this.chartName == '实际电流') {
						option.yAxis.max = parseInt(this.chatsData.data.yData[0][warnMax]);
					}
				}
			},
			init() {
				this.data = this.chatsData.data;
				this.msgText = "";
				this.msgText = "测点描述：" + (this.data.comm ? this.data.comm : "") + " ";
				this.msgText += "数据来源：";
				if (this.data.type == 0) {
					this.msgText += '圣名科技'
				} else if (this.data.type == 1) {
					this.msgText += '西安因联'
				} else if (this.data.type == 2) {
					this.msgText += '容知日新'
				}
				this.msgText += " ";
				if (this.data.fault_freq) {
					for (let item of this.data.fault_freq) {
						this.msgText += item + " ";
					}
				}
				// this.dataZoomStart = 0;
				// this.dataZoomEnd = 98;
				this.initOption();
				tempChats = this.$echarts.init(this.$refs.tempChats);
				tempChats.setOption(option, true);
				this.clearAllPoint();
				tempChats.getZr().off("click");
				//缩放时记录缩放的范围
				tempChats.on('dataZoom', (event) => {
					if (event.batch) {
						this.dataZoomStart = event.batch[0].start;
						this.dataZoomEnd = event.batch[0].end;
					} else {
						this.dataZoomStart = event.start;
						this.dataZoomEnd = event.end;
					};
				});
				//右键弹出菜单，支持点击空白处
				tempChats.getZr().on("contextmenu", (params) => {
					this.clickZr(params, () => {
						//打开右键菜单
						this.pointContext = this.currentPoint.name;
						this.$refs.rightMenu.style['display'] = 'block';
						this.$refs.rightMenu.style['left'] = params.offsetX + 30 + "px";
						this.$refs.rightMenu.style['top'] = params.offsetY + 30 + "px";
					})
				});
				tempChats.getZr().on("click", (params) => {
					this.clickZr(params, () => {
						if (this.seriesPoints[0].name) {
							this.$emit("clickPoint", this.seriesPoints[0].name);
						}
					})
				});
			},
			//点击空白处设置数据
			clickZr(params, func) {
				const pointInPixel = [params.offsetX, params.offsetY];
				this.seriesPoints = [];
				//对series遍历
				let index = tempChats.convertFromPixel({
					seriesIndex: 0
				}, [params.offsetX, params.offsetY]);
				let xIndex = index[0];
				let yIndex = index[1];
				let op = option;
				// 获取当前点击位置对应折线上的数据
				var xData = op.xAxis.data[xIndex]
				var yData = op.series.data[xIndex]
				//记录所有坐标系的点
				var pointObj = {
					name: xData,
					value: yData,
					xIndex: xIndex,
					yIndex: yIndex,
					seriesIndex: 0
				}
				this.seriesPoints.push(pointObj);
				//单独记录所点坐标系的点
				if (tempChats.containPixel({
						seriesIndex: 0
					}, pointInPixel)) {
					this.currentPoint.name = xData;
					this.currentPoint.value = yData;
					this.currentPoint.xIndex = xIndex;
					this.currentPoint.yIndex = yIndex;
					this.currentPoint.seriesIndex = 0;
				}
				func();
			},
			//设置标记
			setPoint() {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				const markPoint = {
					value: this.pointContext,
					xAxis: String(this.currentPoint.name),
					yAxis: String(this.currentPoint.value),
				};
				var flag = true;
				//添加之前先检查是否存在
				for (var item of this.markList) {
					if (item.seriesIndex == this.currentPoint.seriesIndex) {
						item.markPoint.push(markPoint);
						flag = false;
						break;
					}
				}
				//flag为true，表示数组中不存在
				if (flag) {
					var markObj = {
						markPoint: [markPoint],
						seriesIndex: this.currentPoint.seriesIndex,
					}
					this.markList.push(markObj);
				}
				for (var item of this.markList) {
					option.series[item.seriesIndex].markPoint = {
						itemStyle: {
							color: "#66ccff",
						},
						label: {
							color: "#ffffff",
						},
						data: item.markPoint,
					};
				}
				tempChats.setOption(option, true);
			},
			//清除单个标记
			clearOnePoint() {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				//找出是否存在这个点，存在就删除
				for (var i in this.markList) {
					var item = this.markList[i];
					var flag = false;
					for (var i2 in item.markPoint) {
						var item2 = item.markPoint[i2];
						if (item2.xAxis - this.currentPoint.name < 1 && item2.xAxis - this.currentPoint
							.name > -1 && item.seriesIndex == this.currentPoint.seriesIndex) {
							item.markPoint.splice(i2, 1);
						}
						if (item.markPoint.length == 0) {
							flag = true;
						}
					}
					if (flag) {
						this.markList.splice(i, 1);
					}
				}
				//重新画标记
				for (var item of this.markList) {
					option.series[item.seriesIndex].markPoint = {
						itemStyle: {
							color: "#66ccff",
						},
						label: {
							color: "#ffffff",
						},
						data: item.markPoint,
					};
				}
				tempChats.setOption(option, true);
			},
			//清除所有标记
			clearAllPoint() {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				this.markList = [];
				option.series.markPoint = {
					itemStyle: {
						color: "#66ccff",
					},
					label: {
						color: "#ffffff",
					},
					data: [],
				};
				tempChats.setOption(option, true);
			},
		},
	};
</script>

<style lang='scss' scoped>
	.chartDiv {
		.active {
			background-color: #2388ff;
		}

		.tempChats {
			height: 85vh;
			width: 100%;
			background-color: #000B17;
		}

		.tempChats.fsCharts {
			height: 100vh;
			width: 100%;
			background-color: #000B17;
		}

		.bg99 {
			background-color: #000B17;
		}

		.btnSome {
			height: 60px;
			width: 60px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			cursor: pointer;
		}

		.btnSome:hover {
			opacity: 1;
			color: #aaa;
		}

		// .rightChange {
		//    background-color: #596067;
		// }
		.rightBtn {
			background-color: #596067;
			border-radius: 8px;
			overflow: hidden;
		}
	}

	.rightClickMenu {
		/*这个样式不写，右键弹框会一直显示在画布的左下角*/
		position: absolute;
		background-color: rgb(255, 255, 255, 0.8);
		color: rgb(0, 0, 0, 1);
		border-radius: 5px;
		left: -99999px;
		top: -999999px;
	}


	.rightClickMenu>div>.el-button {
		cursor: pointer;
		margin: 0;
		padding: 5px 10px;
		font-size: 14px;
		display: block;
		width: 100%;
		color: #000000;
	}

	.rightClickMenu>div>.el-button:hover {
		background-color: rgb(64, 158, 255, 0.8);
	}
</style>
